<template>
  <div>
      <router-view></router-view>
  </div>
</template>

<script>

import Header from '@/components/Header'
import Footer from '@/components/Footer'

import '../public/css/global.css'

export default {
  name: 'App',

  components: {
    Header,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>