<template>
    <v-container>
        <img width="100px" src="@/assets/iconos/pedido-fallido-1.png">
        <p class="negro">Pedido Fallido</p>
    </v-container>
    
</template>

<script>
export default {
    name:'pedidoFallido',
}
</script>