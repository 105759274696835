<template>
<div class="contenedorGlobal">
    <div class="linea-tiempo">
        <ul>
            <li class="circulo">
                <pedidoConfirmado :activo="objEstado.pedidoConfirmado"></pedidoConfirmado>
            </li> 
            <li class="circulo">
                <enPreparacion :activo="objEstado.enPreparacion"></enPreparacion>
            </li>
         
            <li class="circulo">
                <enCamino :activo="objEstado.enCamino"></enCamino>
            </li>
           
            <li v-if="objEstado.pedidoEntregado" class="circulo">
               <pedidoEntregado :activo="objEstado.pedidoEntregado"></pedidoEntregado>
            </li>
            <li v-else-if="objEstado.pedidoFallido" class="circulo">
                <pedidoFallido></pedidoFallido>
            </li>
            <li v-else class="circulo">
                <pedidoEntregado :activo="objEstado.pedidoEntregado"></pedidoEntregado>
            </li>
            
        </ul>
    </div>
   
    <div id="lineaContenedorCamion">
  
       
        <div id="progresoLinea" 
                    :class="{
                            progresoLineaCompra:this.objEstado.linea.progresoLineaCompra,
                            progresoLineaPreparacion:this.objEstado.linea.progresoLineaPreparacion,
                            progresoLineaEnCamino:this.objEstado.linea.progresoLineaEnCamino,
                            progresoLineaEntregado:this.objEstado.linea.progresoLineaEntregado,
                            progresoLineaProblemas:this.objEstado.linea.progresoLineaProblemas
                            }"
                    >
        </div>
      
    </div>
    
 </div>   
</template>
<style>
    .contenedorGlobal {
        position:relative;
        height:170px;
        padding-top:1%;
        padding-left:20%;
    }

    #progresoLinea {
        background:#d62324;
        height:7px;
        margin-top:-3px;
    }

    #lineaContenedorCamion {
        width:77%;
        margin:auto;
        background:#CCCCCC;
        height:3px;
        position:absolute;
        left:12%;
        top:190px;
        z-index:1;
        border-radius:50px;
        transition:all ease 0.9s;
    }

    .progresoLineaCompra
    {
        width:10%
    }

    .progresoLineaPreparacion
    {
        width:38%;
    }

    .progresoLineaEnCamino
    {
        width:67%
    }

    .progresoLineaEntregado
    {
        width:100%;
    }

    .progresoLineaProblemas
    {
        width:100%;
        background:#d14d24 !important;
    }

    .linea-tiempo {
       width:100%;
    }

    .icono {
        font-size:60px !important;
    }

    .circulo{
        float:left;
        margin-left:0%;
        list-style:none;
        text-align:center;
        margin-right:10%;
    }

    @media only screen and (max-width:950px)
    {
        .circulo{
            margin-right:10%;
        }
    }

    @media only screen and (max-width:745px)
    {
        .contenedorGlobal {
            display:none;
        }
    } 

    .camion{
        font-size:70px !important;
        top:-55px;
        color:#d62324 !important;
    }

    .inactivo {
        color:#CCCCCC !important;
    }

    .activo {
        color:#d62324 !important;
    }

    .pendiente {
        color:#757575 !important;
    }

    .problemas {
        color: #d14d24 !important;
    }

   .camionInicio
   {
       margin-left:0%;
       margin-top:-13%;
   }

   .camionPreparacion
   {
       margin-left:88%;
   }

   .camionEnCamino {
       margin-left:93.5%;
   }

   .camionFinal {
       margin-left:93%;
   }

   .camionProblemas{
       color:#d14d24 !important;
       margin-left:93%;
   }

   .intermedio 
   {
       margin-left:22%;
   }

   .final {
       margin-left:23%;
   }
</style>

<script>

import enCamino from '@/components/iconos/enCamino.vue'
import enPreparacion from '@/components/iconos/enPreparacion.vue'
import pedidoConfirmado from '@/components/iconos/pedidoConfirmado.vue'
import pedidoEntregado from '@/components/iconos/pedidoEntregado.vue'
import pedidoFallido from '@/components/iconos/pedidoFallido.vue'

export default({
    name:'LineaTiempo',
    data() {
        return {
            objEstado:{},
            textoMensaje:'Producto entregado',
            textoMensajeError:'Problemas para entregar el producto',
            valorInternoCamion:''
        } 
    },

    components: {
        enCamino,
        enPreparacion,
        pedidoConfirmado,
        pedidoEntregado,
        pedidoFallido
    },
    

    props:[
        "valorCamion"
        ],

    
    created() {
        this.defineEstados()
    }, 

    methods : {
        defineEstados() {
              
            switch(this.valorCamion) 
            {
                case 'confirmada' : 
                   this.objEstado =
                    {
                        pedidoConfirmado:true,
                        enPreparacion:false,
                        enCamino:false,
                        pedidoEntregado:false,
                        pedidoFallido:false,

                        linea: {
                                progresoLineaCompra:true,
                                progresoLineaPreparacion:false,
                                progresoLineaEnCamino:false,
                                progresoLineaEntregado:false,
                                progresoLineaProblemas:false,
                            }
                    }

                break
                case 'principio' : 
                    this.objEstado =
                    {
                        pedidoConfirmado:false,
                        enPreparacion:true,
                        enCamino:false,
                        pedidoEntregado:false,
                        pedidoFallido:false,

                        linea: {
                                progresoLineaCompra:false,
                                progresoLineaPreparacion:true,
                                progresoLineaEnCamino:false,
                                progresoLineaEntregado:false,
                                progresoLineaProblemas:false,
                            }
                    }

                break
                case 'intermedio' : 
                    this.objEstado =
                    {
                        pedidoConfirmado:false,
                        enPreparacion:false,
                        enCamino:true,
                        pedidoEntregado:false,
                        pedidoFallido:false,
                        
                        linea: {
                                progresoLineaCompra:false,
                                progresoLineaPreparacion:false,
                                progresoLineaEnCamino:true,
                                progresoLineaEntregado:false,
                                progresoLineaProblemas:false,
                            }
                    }
                break
                case 'final' : 
                    this.objEstado =
                    {
                        pedidoConfirmado:false,
                        enPreparacion:false,
                        enCamino:false,
                        pedidoEntregado:true,
                        pedidoFallido:false,
                        
                        linea: {
                                progresoLineaCompra:false,
                                progresoLineaPreparacion:false,
                                progresoLineaEnCamino:false,
                                progresoLineaEntregado:true,
                                progresoLineaProblemas:false,
                            }
                    }
                break

                case 'problema': 
                this.objEstado =
                    {
                        pedidoConfirmado:false,
                        enPreparacion:false,
                        enCamino:false,
                        pedidoEntregado:false,
                        pedidoFallido:true,

                        linea: {
                                progresoLineaCompra:false,
                                progresoLineaPreparacion:false,
                                progresoLineaEnCamino:false,
                                progresoLineaEntregado:false,
                                progresoLineaProblemas:true,
                            }
                        
                    }
                break
                default : 
                    this.objEstado =
                    {
                        pedidoConfirmado:true,
                        enPreparacion:false,
                        enCamino:false,
                        pedidoEntregado:false,
                        pedidoFallido:false,

                        linea: {
                                progresoLineaCompra:true,
                                progresoLineaPreparacion:false,
                                progresoLineaEnCamino:false,
                                progresoLineaEntregado:false,
                                progresoLineaProblemas:false,
                            }
                    }
                break
            }
          
            }
                        
        }
    }
)
</script>