<template>
    <div>
        <v-container v-if="activo">
            <img width="100px" src="@/assets/iconos/en-camino-2.png">
            <p class="rojo">En camino</p>
        </v-container>
        <v-container v-else>
            <img width="100px" src="@/assets/iconos/en-camino-1.png">
            <p class="negro">En camino</p>
        </v-container>
    </div>  
</template>

<script>
export default {
    name:'enCamino',
    props:['activo']
}
</script>