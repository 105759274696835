<template>
    <div>
        <v-container v-if="activo">
                <img width="100px" src="@/assets/iconos/pedido-entregado-2.png"></img>
                <p class="rojo">Pedido entregado</p>
        </v-container>
        <v-container v-else>
                <img width="100px" src="@/assets/iconos/pedido-entregado-1.png"></img>
                <p class="negro">Pedido entregado</p>
        </v-container>
    </div>
    
    
</template>

<script>
export default {
    name:'pedidoEntregado',
    props:['activo']
}
</script>