<template>
    <l-map
        :center=coordenadas
        :zoom="zoom"
        style="width:100%;height:250px;"
       
        @update:zoom="zoomUpdated"
        @update:bounds="boundsUpdated"
        @update:center="centerUpdated">
             <Marcador :latitud="latitud" :longitud="longitud"/> 
             <l-tile-layer
                :url="url">
            </l-tile-layer>
    </l-map>
</template>

<style>

</style>



<script>
/*import "leaflet/dist/leaflet.css" */
import Marcador from '@/components/Marcador.vue'
import {LMap, LTileLayer} from 'vue2-leaflet'

export default {
    name:'Mapa',
    components: {
        LMap,
        LTileLayer,
        Marcador
    },
    props: [
       "latitud","longitud"
    ],
         
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            coordenadas:[],
            zoom:13,
            bounds:null
        }
    },

    created() {
       this.coordenadas=['-33.38580', '-70.74924']
    },


    watch: {
        latitud: function(valorAntiguo,valorNuevo) { 
                this.coordenadas=[this.latitud,this.longitud]
            }
    },

   

    methods : {

        zoomUpdated(zoom) {
            this.zoom=zoom
        }, 

        centerUpdated(coordenadas) {
            this.coordenadas=coordenadas
        },

        boundsUpdated(bounds) {
            this.bounds=bounds
        }

        
    }, 

}
</script>
